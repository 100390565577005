export const ActionTypes = {
  // SET_INFOOS: "SET_INFOOS",
  // GET_INFOO: "GET_INFOO",
  // SET_TENANTS: "SET_TENANTS",
  // GET_TENANT: "GET_TENANT",
  // SET_ADMINS: "SET_ADMINS",
  // GET_ADMIN: "GET_ADMIN",
  GET_LOGIN: "GET_LOGIN",
  SET_LOGINS: "SET_LOGINS",
  GET_WEIGHT: " GET_WEIGHT",
  SET_WEIGHTS: "SET_WEIGHTS",
  GET_SIZE: " GET_SIZE",
  SET_SIZES: "SET_SIZES",
  GET_ACCOUNT: " GET_ACCOUNT",
  SET_ACCOUNTS: "SET_ACCOUNTS",

};