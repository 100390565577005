import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../LoginPage/login.css";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [create_email, setEmail] = useState("");
  const [create_password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Function to store user information after login
  const storeUserInfo = (userId) => {
    localStorage.setItem("userId", userId); // Store user ID in local storage
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ create_email, create_password }),
      });
  
      const responseData = await response.json(); // Parse response body as JSON
  
      if (response.status === 200) {
        console.log('Login successful');
        window.alert('Login successfully');
        storeUserInfo(responseData.userId); // Store user ID from response data
        navigate(`/MainTenant?email=${create_email}`);
      } else {
        console.log('Login failed');
        setError('Invalid credentials or transfer not approved');
        window.alert('Invalid credentials or account not approved');
      }  
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <img src={require("../img/REDDONIONN.jpg")} className="BACKPICC" alt="logo" />
      <div className="container">
        <div className="login-container">
          <div className="logo-container">
            <div className="logo">
              <img src={require("../img/MAINPIC.png")} className="MAINPIC" alt="logo" />
            </div>
          </div>
          <div className="login-form">
            <div className="login-header">
              <h2>Login</h2>
            </div>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="email"
                  name="create_email"
                  value={create_email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="create_password"
                  value={create_password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                />
              </div>
              {error && <div className="error-message">{error}</div>}
              <Link to="/HomePage"><button className="login-button1">Back</button></Link>
              <button className="login-button" type="submit">Login</button>
              <Link to="/CreateAccount" className="create-account-link">Create an Account</Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
