import React, { useState, useEffect } from 'react';
import './AccountPage.css'; // Import your CSS file for styling
import Main from '../Main/Main'; // Import your Navigation Bar component

const AccountPage = () => {
  const [transfers, setTransfers] = useState([]);
  const [error, setError] = useState(null); // Add error state to handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/transfers');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setTransfers(data);
      } catch (error) {
        setError(error.message); // Set error state
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const updateIsApproved = async (id, value) => {
    try {
      const response = await fetch(`https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/transfers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_approved: value }) // Stringify the JSON object
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update account status: ${response.status} ${response.statusText}`);
      }
  
      setTransfers(prevTransfers => {
        return prevTransfers.map(transfer => {
          if (transfer.id === id) {
            return {...transfer, is_approved: value };
          }
          return transfer;
        });
      });
  
      alert(`Account ${value === '1'? 'has been approved' : 'not approved'}`);
    } catch (error) {
      setError(error.message);
      console.error('Error updating data:', error);
      alert(`Error updating account status: ${error.message}`);
    }
  };

  const togglePasswordVisibility = (id) => {
    setTransfers(prevTransfers => {
      return prevTransfers.map(transfer => {
        if (transfer.id === id) {
          return {...transfer, showPassword: !transfer.showPassword };
        }
        return transfer;
      });
    });
  };
  
  return (
    <div>
      <Main /> {/* Include your Navigation Bar component here */}
      <div className="account-page-container">
        <h1 className="account-page-header">Account Page</h1>
        {/* {error && <div className="error-message">{error}</div>} // Display error message */}
        <table className="account-page-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Password</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transfers.map((item) => (
              <tr key={item.id}>
                <td>{item.create_email}</td>
                <td>
                  {item.showPassword ? item.create_password : "••••••••••"} {/* Display password or dots */}
                  <button onClick={() => togglePasswordVisibility(item.id)}>{item.showPassword ? "Hide" : "Show"}</button> {/* Button to toggle visibility */}
                </td>
                <td>
                  <select value={item.is_approved} onChange={(e) => updateIsApproved(item.id, e.target.value)} className="action-select">
                    <option value="">Select an action</option>
                    <option value="1">Approve</option>
                    <option value="0">Not Approve</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountPage;
