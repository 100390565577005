import React, { useState, useEffect } from 'react';
import Main from '../Main/Main';
import '../MainTenant/MainTenant.css';
import { useDispatch, useSelector } from 'react-redux';
import { setWeights, setAccounts } from '../../redux/actions/loginActions';
import { Link, useLocation } from 'react-router-dom';
import http from '../../http';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function MainTenant() {
  const dispatch = useDispatch();
  const weights = useSelector((state) => state.allWeights.weights);
  const accounts = useSelector((state) => state.allAccounts.accounts);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSize, setSelectedSize] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const printedBy = params.get('email');
  console.log('printedBy:', printedBy)

  useEffect(() => {
    fetchWeights();
    fetchAccounts();
  }, []);

  const fetchWeights = async () => {
    try {
      const response = await http.get('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/weights');
      const data = response.data;
      dispatch(setWeights(data));
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchAccounts = () => {
    http
     .get('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/accounts')
     .then((result) => {
        dispatch(setAccounts(result.data));
      })
     .catch((err) => console.log(err.message));
  };

  const filteredWeights = weights.filter(weight => {
    const date = new Date(weight.Date);
    return date.getFullYear() === selectedDate.getFullYear() &&
           date.getMonth() === selectedDate.getMonth() &&
           date.getDate() === selectedDate.getDate() &&
           (!selectedSize || weight.Size === selectedSize);
  });

  const totalWeight = filteredWeights.reduce((acc, weight) => {
    if (weight.individualweight >= 3) {
      acc += weight.individualweight;
    }
    return acc;
  }, 0);

  const handleGeneratePdf = () => {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const table = document.getElementById('pdf-table');
    const date = selectedDate.toDateString();
    const today = new Date().toLocaleDateString();
  
    pdf.setFontSize(12);
    pdf.setFont('helvetica');
    pdf.text('Harvest Report', 300, 50, null, null, 'center');
    pdf.text(`Date printed: ${today}`, 20, pdf.internal.pageSize.height - 10);
    pdf.text(`Harvest Date: ${date}`, 20, 80);
  
    html2canvas(table, {
      useCORS: true,
      scale: 2,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgHeight = canvas.height * 210 / canvas.width * 2;
  
      let position = 100;
      let page = 1;
  
      pdf.addImage(imgData, 'PNG', 20, position, 550, imgHeight);
  
      if (imgHeight > 700) {
        position = 10;
        pdf.addPage();
        page++;
      }
  
      pdf.text(`This was printed by: ${printedBy}`, 20, pdf.internal.pageSize.height - 20);
      pdf.save(`harvest_report_${date.replace(/\s+/g, '_')}_page_${page}.pdf`);
    });
  };

  const handleDisplayAllWeights = () => {
    const dateWeights = weights.reduce((acc, weight) => {
      const date = new Date(weight.Date);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }

      acc[formattedDate].push(weight);
      return acc;
    }, {});

    for (const date in dateWeights) {
      console.log(`Date: ${date}`);
      dateWeights[date].forEach((weight, index) => {
        console.log(`Weight ${index + 1}:`, weight);
      });
    }
  };
  const handleTruncateWeights = async () => {
    try {
      await http.post('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/weights/truncate');
      // Display alert message
      alert('Table deleted successfully');
      // Refresh weights after truncating
      fetchWeights();
    } catch (error) {
      console.error('Failed to truncate weights:', error.message);
    }
  };

  return (
    <>
      <Main />
      <div className="containerMainPage">
        <h1>Harvest Table</h1>
        <div className='date-pdf'>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
          />
        </div>
        <button onClick={handleGeneratePdf} className='button-main'>Generate PDF Report</button>
        <button onClick={handleTruncateWeights} className='button-main1'>Truncate Table Weights</button>
       <Link to="/AllWeights"> <button onClick={handleDisplayAllWeights} className='button-main1'>Display All Weights</button></Link>
       <table id="pdf-table">
  <thead>
    <tr>
      <th>Count</th>
      <th>Size
      <select value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
        <option value="">All Sizes</option>
        <option value="Small">Small</option>
        <option value="Medium">Medium</option>
        <option value="Large">Large</option>
      </select>
      </th>
      <th>Date</th>
      <th>Individual Weight</th> {/* Add this line */}
    </tr>
  </thead>
  <tbody>
    {filteredWeights.length > 0 ? (
      filteredWeights
        .filter(weight => weight.individualweight >= 0.1)
        .filter(weight => weight.weight >= 0.41)

        .map((weight, index) => (
          <tr key={index} className="mt-1">
            <th scope="row">{weight.id}</th>
            <td>{weight.Size}</td>
            <td>{weight.Date}</td>
            <td>{weight.individualweight}</td> {/* Add this line */}
          </tr>
        ))
    ) : (
      <tr>
        <td colSpan="5">NO HARVEST</td>
      </tr>
    )}
    <tr>
      <td colSpan="3" style={{ textAlign: 'right' }}>
        <strong>Total Weight:</strong>
      </td>
      <td>
        <strong>{totalWeight}</strong>
      </td>
    </tr>
  </tbody>
</table>
      </div>
</>
  );
}

export default MainTenant;