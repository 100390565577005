import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import http from "../../http";
import { setAccounts } from "../../redux/actions/loginActions";
import "./CreateAccount.css"; // Assuming CreateAccount.css is in the same directory

function CreateAccount () {
  const dispatch = useDispatch();
  const [create_email, setcreate_email] = useState("");
  const [create_password, setcreate_password] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const accountData = {
      create_email: create_email,
      create_password: create_password,
    };

    http.post("https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/transfers", accountData)
      .then((result) => {
        console.log(result.data);
        // You might want to dispatch an action here to update Redux store with the new account data
        dispatch(setAccounts(result.data));
        alert("Your account is under approval.");
      })
      .catch((err) => console.log(err.message));

    setcreate_email("");
    setcreate_password("");
  };

  return (
    <main>
    <div className="create-account-container">
      <div className="create-account-form">
        <h2 className="create-account-header">Create Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={create_email}
              onChange={(e) => setcreate_email(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={create_password}
              onChange={(e) => setcreate_password(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirm-password"
              placeholder="Confirm Password"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block btn-create" onClick={() => alert("Your account is under approval.")}>Create Account</button>
        </form>
        <div className="login-link">
          <p>Already have an account? <Link to="/Login">Login here</Link></p>
        </div>
      </div>
    </div>
    </main>
  );
}

export default CreateAccount;
