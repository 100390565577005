import React from "react";
import "../Footer/footer.css"

function Footer() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
      ></link>
    <footer className="footer ">
    
     <br/>
        <div className="container-md fs-4">
          <div className="text-center">
          &copy; <span id="copyright-year"> All Rights Reserved </span>
          </div>
          <div className="text-center">
            Developed by: FarmROS WEB-DEVELOPERS
          </div>
          <div className="linkweb align-self-center">
            <a
              href="https://www.facebook.com/people/Farmros/61558154126772/?mibextid=ZbWKwL"
              className="bi bi-facebook text-center"
            />
            Visit us on facebook
            <a
              href="https://twitter.com/86_CBuilding"
              className="linkweb1 bi bi-twitter text-center"
            />
            Visit us on Twitter
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
