import logo from "../src/components/img/logo.jpg";
import "./App.css";
import Homepage from "./components/HomePage/HomePage";
import { BrowserRouter, Routes, Route,  } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import LoginPage from "./components/LoginPage/LoginPage";
import "./Assets/css/style.css";
import AboutPage from "./components/AboutPage/AboutPage";
import ContactPage from "./components/ContactPage/ContactPage";
import Main from "./components/Main/Main";
import Report from "./components/Report/Report";
import HomePage from "./components/HomePage/HomePage";
import MainTenant from "./components/MainTenant/MainTenant";
import Footer from "./components/Footer/Footer";
// import store from "./store.js";

import Header from "./components/Header/Header.js";
import CreateAccount from "./components/Register/CreateAccount.js";
import AccountPage from "./components/AccountPage/AccountPage.js";
import AllWeights from "./components/AllWeights/AllWeights.js";


function App() {
  return (
    <>
    
      <BrowserRouter>
        <Routes>
        <Route
            path="/"
            element={
              <HomePage>
                </HomePage>
                
            }
          />
          <Route path="Header" element={<Header/>} />
          <Route path="Login" element={<LoginPage />} />
          <Route path="HomePage" element={<HomePage />} />
          <Route path="AboutPage" element={<AboutPage />} />
          <Route path="Footer" element={<Footer />} />
          <Route path="ContactPage" element={<ContactPage />} />
          <Route path="MainTenant" element={<MainTenant />} />
          <Route path="Main" element={<Main />} />
          <Route path="Report" element={<Report />} />
          <Route path="AllWeights" element={<AllWeights/>}/>
          <Route path="CreateAccount" element={<CreateAccount/>}/>
          <Route path="AccountPage" element={<AccountPage/>}/>


          {/* <Route path="Logout" element={<Logout />} /> */}
        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
