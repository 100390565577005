import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../AboutPage/AboutPage.css"
import { Navigate } from "react-router-dom";

function AboutPage() {
  return (
    <>
      {/* <img
            src={require("../img/Onions.jpg")}
            className="background-thumbnail"
            alt="logo"
          /> */}


   
      <body>
      <div className="backgroundPicture">
      <Header />



<section>
    <h2>FarmROS</h2>
    <p>Welcome to FarmROS, where you can separate the Red Onions according to Size. </p>
</section>


<section>
    <h2>Conveyor Belt to Arduino</h2>
    <p className="aboutP">Implementing
automatic segregation on a conveyor belt based on size using Arduino involves.
incorporating weight sensors or load cells into the system. A conveyor belt.
transports objects from one end to another. The load cells or weight
sensors are strategically placed at the end of conveyor belt to measure the
weight of the objects. Load cells can measure the force applied to them, and
this force can be converted into weight.</p>

    
</section>

<div class="wholecontainer">

<h2>Our Team</h2>

<div class="team-container">

    <div class="team-member">
     <img
            src={require("../img/lenardd.png")}
            className="team-picture"
            alt="Lenard Dimaunahan"
          />
        <div class="member-info">
            <h3>Lenard P. Dimaunahan</h3>
            <p>Bachelor of Science in Information and Technology</p>
        </div>
    </div>

    <div class="team-member">
    <img
            src={require("../img/Marinn.png")}
            className="team-picture"
            alt="Emmanuel Marin"
          />
        <div class="member-info">
            <h3>Emmanuel Marin</h3>
            <p>Bachelor of Science in Information and Technology</p>
        </div>
    </div>

    <div class="team-member">
    <img
            src={require("../img/chandlerr.png")}
            className="team-picture"
            alt="Chandler Gary Mercado"
          />
        <div class="member-info">
            <h3>Chandler Gary Mercado</h3>
            <p>Bachelor of Science in Information and Technology</p>
        </div>
    </div>
    <div class="team-member">
    <img
            src={require("../img/Clarkkkkk.png")}
            className="team-picture"
            alt="Clark Keint Aguilar"
          />
        <div class="member-info">
            <h3>Clark Keint Aguilar</h3>
            <p>Bachelor of Science in Information and Technology</p>
        </div>
    </div>
    <div class="team-member">
    <img
            src={require("../img/reynoldd.png")}
            className="team-picture"
            alt="Reynold Felomino"
          />
        <div class="member-info">
            <h3>Reynold Felomino</h3>
            <p>Bachelor of Science in Information and Technology</p>
        </div>
    </div>



</div>

</div>

</div>

<div class="Background">
<section class="contact-info">
    <h2>Contact Us</h2>
    <p>If you have any questions or inquiries, feel free to reach out to us:</p>
    <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=FarmROS@gmail.com"> &#x2709;
    FarmROS@gmail.com</a>
    <p>Phone: +63 9472431433</p>
</section>
</div>

</body>
<Footer></Footer>

    </>
  );
}

export default AboutPage;
