import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost/FarmROS-BACKEND/FarmROS-BACKEND/my-app-be/public/api/",
  baseURL: "https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/",

  headers: {
    "Content-Type": "application/json",
  },
});
