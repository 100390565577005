import { setLoginReducer, getLoginReducer, setWeightReducer, getWeightReducer, setSizeReducers, getSizeReducers, setAccountReducers, getAccountReducers,} from "../reducers/LoginReducers"
import { combineReducers } from "redux";

const reducers = combineReducers({
  allLogins: setLoginReducer,
  getLogin: getLoginReducer,
  allWeights: setWeightReducer,
  getWeight: getWeightReducer,
  allSizes: setSizeReducers,
  getSize: getSizeReducers,
  allAccounts: setAccountReducers,
  getAccount: getAccountReducers,


});
export default reducers;