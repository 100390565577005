import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Main from '../Main/Main';
import http from '../../http';
import { useLocation } from 'react-router-dom';
import { setWeights, setAccounts } from '../../redux/actions/loginActions';
import './AllWeights.css'; // Import your custom CSS file for styling
import jsPDF from 'jspdf';

const AllWeights = () => {
  const dispatch = useDispatch();
  const weights = useSelector((state) => state.allWeights.weights);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [printedBy, setPrintedBy] = useState(params.get('email')); // Initialize printedBy with the URL parameter

  useEffect(() => {
    // Update printedBy when the location changes
    setPrintedBy(params.get('email'));
  }, [location.search]); // Re-run this effect whenever location.search changes

  // Fetch weights data from API when the component mounts
  useEffect(() => {
    fetchWeights();
    fetchAccounts();
  }, []);

  const fetchAccounts = () => {
    http
     .get('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/accounts')
     .then((result) => {
        dispatch(setAccounts(result.data));
      })
     .catch((err) => console.log(err.message));
  };

  const fetchWeights = async () => {
    try {
      const response = await http.get('https://farmrosonions.com/FarmROS-BACKEND/my-app-be/public/api/weights');
      const data = response.data.map((weightData) => {
        return {
          ...weightData,
          individualweight: parseFloat(weightData.individualweight), // Parse the individualweight as a float
        };
      });
      dispatch(setWeights(data));
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to extract month from date string
  const getMonthFromDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[date.getMonth()];
  };

  // Organize weights by size and month
  const weightsBySizeAndMonth = weights.reduce((acc, weight) => {
    if (!acc[weight.Size]) {
      acc[weight.Size] = {};
    }
    const month = getMonthFromDate(weight.Date);
    if (!acc[weight.Size][month]) {
      acc[weight.Size][month] = [];
    }
    acc[weight.Size][month].push(weight);
    return acc;
  }, {});

  // Function to handle size selection
  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  // Function to handle month selection
  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
  };

  // Calculate total weight for the selected size and month
  const totalWeight = selectedSize && selectedMonth ? 
    weightsBySizeAndMonth[selectedSize]?.[selectedMonth]?.filter(weight => weight.individualweight >= 3)?.reduce((total, weight) => total + weight.individualweight, 0) || 0 :
    0;

// Function to generate PDF report
const handleGeneratePdf = () => {
  const pdf = new jsPDF();
  const reportTitle = `Total Harvest Report (${selectedSize}, ${selectedMonth})`;
  const totalWeightText = totalWeight > 0 ? `Total Weight: ${totalWeight}g` : 'NO HARVEST IN THIS MONTH'; // Check if totalWeight is greater than 0
  const datePrintedInfo = `Date Printed: ${new Date().toLocaleDateString()}`;

  // Title
  pdf.setFontSize(18);
  pdf.setFont('helvetica', 'bold');
  pdf.text(reportTitle, 20, 20); // Added reportTitle here

  // Printed By and Date Printed Info
  pdf.setFontSize(10);
  pdf.text(`This was printed by: ${printedBy || 'Unknown'}`, 20, 30);
  pdf.text(datePrintedInfo, 20, 35);

  // Total weight
  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(12);
  pdf.text(totalWeightText, 20, 45);

  if (totalWeight > 0) { // Only add table if there are weights for the selected month
    // Add some design elements
    pdf.setLineWidth(0.5);
    pdf.line(20, 50, 190, 50); // Horizontal line below the title
    pdf.setFillColor(200, 200, 200); // Light gray fill color
    pdf.rect(20, 55, 170, 10, 'F'); // Gray background behind the total weight text

    // Add a table with individual weights
    const tableColumnWidths = [60, 60]; // Widths for the two columns: individualweight and date
    const tableTopMargin = 70;
    const tableLeftMargin = 20;

    // Add table header
    pdf.setFontSize(12);
    pdf.setFont('helvetica', 'bold');


    // Add table rows
    pdf.setFont('helvetica', 'normal');
    weightsBySizeAndMonth[selectedSize][selectedMonth]
      .filter(weight => weight.individualweight >= 3)
      .forEach((weight, index) => {
        const yPos = tableTopMargin + (index + 1) * 10;

      });
  }

  // Save the PDF
  pdf.save(`total_harvest_report_${selectedSize}_${selectedMonth}.pdf`);
};


  return (
    <>
      <Main />
      <div className="all-weights-container">
  <h2 className="title">View the Total Weight</h2>
  <div className="button-container"> {/* Container to center the button */}
    <button className="generate-pdf-btn" onClick={handleGeneratePdf}>
      <span className="btn-text">Generate PDF Report</span>
      <span className="btn-icon">📄</span>
    </button>
  </div>
  <div className="selectors">
    <div className="size-selection">
      <label className="label">Select Size:</label>
      <select className="select" onChange={(e) => handleSizeSelect(e.target.value)}>
        <option value="">All Sizes</option>
        <option value="Small">Small</option>
        <option value="Medium">Medium</option>
        <option value="Large">Large</option>
      </select>
    </div>
    <div className="month-selection">
      <label className="label">Select Month:</label>
      <select className="select" onChange={(e) => handleMonthSelect(e.target.value)}>
        <option value="">Select Month</option>
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </select>
    </div>
  </div>
  <div className="total-weight">
    {totalWeight > 0 ? ( // Check if totalWeight is greater than 0
      <p>Total Weight: {totalWeight}g</p>
    ) : (
      <p>NO HARVEST THIS MONTH</p>
    )}
  </div>
</div>

    </>
  );
};

export default AllWeights;