import { ActionTypes } from "../constants/action-types";

const loginInitialstates = {
  logins: [
    {
      // id: "1",
      // email: "admin@gmail.com",
      // password: "12345",
    },
  ],

};


export const setLoginReducer = (
  state = loginInitialstates,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_LOGINS:
      return { ...state, logins: payload };
    default:
      return state;
  }
};

export const getLoginReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_LOGIN:
      return { logins: [...state, ...payload] };
    case ActionTypes.GET_INFOO:
      return { logins: [...state, ...payload] };
    default:
      return state;
  }
};

const weightsInitialstates = {
  weights: [
    {
      // id: "1",
      // date
      // size
    },
  ],

};


export const setWeightReducer = (
  state = weightsInitialstates,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_WEIGHTS:
      return { ...state, weights: payload };
    default:
      return state;
  }
};

export const getWeightReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_WEIGHT:
      return { weights: [...state, ...payload] };
    case ActionTypes.GET_WEIGHT:
      return { weights: [...state, ...payload] };
    default:
      return state;
  }
};


const sizesInitialstates = {
  sizes: [
    {
      // id: "1",
      // date
      // size
    },
  ],

};


export const setSizeReducers = (
  state = sizesInitialstates,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_SIZES:
      return { ...state, sizes: payload };
    default:
      return state;
  }
};

export const getSizeReducers = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_SIZE:
      return { sizes: [...state, ...payload] };
    case ActionTypes.GET_SIZE:
      return { sizes: [...state, ...payload] };
    default:
      return state;
  }
};

const accountsInitialstates = {
  accounts: [{}],

};


export const setAccountReducers = (
  state = accountsInitialstates,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_ACCOUNTS:
      return { ...state, accounts: payload };
    default:
      return state;
  }
};

export const getAccountReducers = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ACCOUNT:
      return { accounts: [...state, ...payload] };
    case ActionTypes.GET_ACCOUNT:
      return { accounts: [...state, ...payload] };
    default:
      return state;
  }
};

