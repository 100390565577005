import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function ContactPage() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="row g-3">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <img
                src={require("../img/lenardd.png")}
                className="card-img-top"
                alt="Lenard Dimaunahan"
              />
              <div className="card-body">
                <h5 className="card-title">Lenard Dimaunahan</h5>
                <p className="card-text">Full Stack Developer - BSIT IT</p>
                <a
                  href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=lenardplatondimaunahan@gmail.com"
                  className="btn btn-primary"
                >
                  EMAIL ME
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <img
                src={require("../img/chandlerr.png")}
                className="card-img-top"
                alt="Chandler Mercado"
              />
              <div className="card-body">
                <h5 className="card-title">Chandler Mercado</h5>
                <p className="card-text">Front-end Developer - BSIT</p>
                <a
                  href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=s2020103692@firstasia.edu.ph"
                  className="btn btn-primary"
                >
                  EMAIL ME
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <img
                src={require("../img/Marinn.png")}
                className="card-img-top"
                alt="Chandler & Marin"
              />
              <div className="card-body">
                <h5 className="card-title">Emmanuel Marin</h5>
                <p className="card-text">Front-end Developer - BSIT</p>
                <a
                  href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=s2020103692@firstasia.edu.ph"
                  className="btn btn-primary"
                >
                  EMAIL ME
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <img
                src={require("../img/Clarkkkkk.png")}
                className="card-img-top"
                alt="Chandler & Marin"
              />
              <div className="card-body">
                <h5 className="card-title">Clark Keint Aguilar</h5>
                <p className="card-text">Back-end Developer - BSIT</p>
                <a
                  href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=s2020103692@firstasia.edu.ph"
                  className="btn btn-primary"
                >
                  EMAIL ME
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <img
                src={require("../img/reynoldd.png")}
                className="card-img-top"
                alt="Reynold"
              />
              <div className="card-body">
                <h5 className="card-title">Reynold T. Felomino</h5>
                <p className="card-text">Back-end Developer</p>
                <a
                  href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=reynoldfelomino@gmail.com"
                  className="btn btn-primary"
                >
                  Email Me
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
