import React from "react";
import { Link } from "react-router-dom";
import "../Header/Header.css";

function Header() {
  return (
    <>
      <div className="tab-container">
        <div>
          <Link to="/HomePage">
            <img
              src={require("../img/logo.png")}
              className="logo-navbar"
              alt="logo"
            />
          </Link>
        </div>
        <div className="Header-tab">
          <Link to="/HomePage">Home</Link>
        </div>
        <div className="Header-tab1">
          <Link to="/AboutPage">About</Link>
        </div>
        <div className="Header-tab2">
          <Link to="/Login">Login</Link>
        </div>
      </div>
    </>
  );
}

export default Header;
