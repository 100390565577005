import React from "react";
import { Link } from "react-router-dom";
import "../Main/Main.css"

function Main() {
  return (
    <>
    <div class="tab-container1">

<div>         
<img
            src={require("../img/logo.png")}
            className="logo-navbar"
            alt="logo"
            
          />
          </div> 
          <div className="NAVMAIN">
          <div className="Main-tab">
          <Link to="/MainTenant">MainTenant</Link>
        </div>
        <div className="Main-tab1">
          <Link to="/AccountPage">Accounts</Link>
        </div>
        <div className="Main-tab2">
          <Link to="/HomePage">Logout</Link>
        </div>
      </div>
      </div>
    </>
  );
}

export default Main;
