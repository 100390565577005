import React from "react";
import Header from "../Header/Header";
import "../HomePage/HomePage.css"
import Footer from "../Footer/Footer";


function HomePage() {
  return (
    <>
    <body>
      
      <div className="home-container">
    
      <img
            src={require("../img/Onions.jpg")}
            className="homepage-thumbnail"
            alt="logo"
           
          />
            <Header /> 
      <div className="row1"> 
        <div className="col1">
          <h1>FarmROS</h1>
          <h4>"Red Onion Sorting"</h4>
          <p>Implementation of Arduino-Based Harvest Material Segregation by Size: Enhancing Agricultural Efficiency and Sustainability"</p>

       
          <div className="card1">
            
            
          </div>
          <div className="card2">
          </div>
          <div className="card3">
          </div>
          <div className="card4">
          </div>


        </div>
     </div>
     </div>
   




















     </body>
     <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
     <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>   <br/>  
     

     <Footer></Footer>
       
    </>
  );
}

export default HomePage;
