import { ActionTypes } from "../constants/action-types";

export const setLogins = (logins) => {
  return {
    type: ActionTypes.SET_LOGINS,
    payload: logins,
  };
};
export const getLogin = (logins) => {
  return {
    type: ActionTypes.GET_LOGIN,
    payload: logins,
  };
};
export const setWeights = (weights) => {
  return {
    type: ActionTypes.SET_WEIGHTS,
    payload: weights,
  };
};
export const getWeight = (weights) => {
  return {
    type: ActionTypes.GET_WEIGHT,
    payload: weights,
  };
};

export const setSizes = (sizes) => {
  return {
    type: ActionTypes.SET_SIZES,
    payload: sizes,
  };
};
export const getSize = (sizes) => {
  return {
    type: ActionTypes.GET_SIZE,
    payload: sizes,
  };
};

export const setAccounts = (accounts) => {
  return {
    type: ActionTypes.SET_ACCOUNTS,
    payload: accounts,
  };
};
export const getAccount= (accounts) => {
  return {
    type: ActionTypes.GET_ACCOUNT,
    payload: accounts,
  };
};